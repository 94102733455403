import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;}
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;

    a {
        color: currentColor;
    }
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 1rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Nejpřívětivější destinace pro seniory</Title>
            <Info>
                <Author>Simona</Author>
                <Date>11. 7. 2023</Date>
            </Info>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/senior/senior_1.jpg" alt="Senioři na dovolené"></StaticImage>
                <StyledFigcaption> Zdroj: <a href="https://pixabay.com/cs/photos/senior-star%C5%A1%C3%AD-lid%C3%A9-p%C3%A1r-osob-star%C3%BD-3336451/">Pixbay.com</a></StyledFigcaption>
            </StyledFigure>

            <p>V dnešním článku vám představíme 5 nejpřívětivějších destinací, které nabízejí skvělé zážitky pro starší cestovatele. Bez ohledu na to, zda se jedná o příjemnou plážovou dovolenou, objevování kulturního bohatství nebo dobrodružství v přírodě, tyto destinace jsou ideální pro relaxaci, poznávání nových míst a vytváření vzpomínek. Připravte se na neuvěřitelné dobrodružství!</p>
            <BlogList>
                <li>
                    <h3>Lisabon, Portugalsko:</h3>
                    <p>Lisabon je fascinujícím městem s bohatou historií a nádhernou architekturou. Pro senior cestovatele je to skvělá destinace, protože nabízí spoustu možností k procházkám po malebných uličkách či užívání si překrásných výhledů z historických tramvají. Starší lidé mohou také objevovat historická místa, jako je věž Belém a klášter Jeronimos, které jsou snadno dostupné. A nezapomeňme na famózní portugalskou kuchyni, která uspokojí i ty nejnáročnější chuťové buňky! Do Lisabonu se z Prahy přímým letem zhruba za 3 hod 40 min, cenové rozpětí může být od 3 500 Kč až po 9 000 Kč dle termínu, času a dopravci. Taktéž podobně se dá do Lisabonu letět i z Vídně.</p>
                </li>
                <li>
                    <h3>Řím, Itálie:</h3>
                    <p>Řím je město plné historie a kultury, které okouzlí každého. Pro starší cestovatele je skvělou volbou, protože nabízí snadnou přístupnost k památkám a kulturním pokladům, jako je Koloseum, Vatikán a Sixtinská kaple. Řím má také rozsáhlou síť veřejné dopravy, která usnadní pohyb po městě. Po prohlídce památek si můžete vychutnat italské speciality v jedné z mnoha útulných restaurací. Do Říma se z Vídně dá odletět za ceny od 2000 Kč, pokud se letenka kupuje s dostatečným předstihem. Z Prahy ve velmi podobné cenové relaci. Před odletem do Říma také doporučujeme některé vstupenky rezervovat s dostatečným předstihem.</p>
                    <StyledFigure>
                    <StaticImage src="../assets/images/blog/senior/senior_2.png" alt="Příklad možného spoje"></StaticImage>
                        <StyledFigcaption>Příklad ceny a odletů z letiště s dopravcem Ryanair (je možné zvolit i Eurowings nebo WizzAir)</StyledFigcaption>
                    </StyledFigure>
                </li>
                <li>
                    <h3>Vancouver, Kanada:</h3>
                    <p>Pokud preferujete dovolenou v přírodě, Vancouver je pro vás ideální volbou. Toto kanadské město je obklopeno úchvatnou přírodou, včetně pohoří North Shore Mountains a Stanley Parku. Pro starší cestovatele je tu mnoho možností pro pohodlné procházky po promenádách a stezkách. Navštívit můžete například Capilano Suspension Bridge, kde se můžete projít po visutém mostě nad korunami stromů. Vancouver nabízí i skvělé restaurace s pestrou nabídkou čerstvých mořských plodů. Zpáteční cena letenek do Vancouveru z Prahy se pohybuje (na září 2023) v rozmezí od 21 000 Kč do 25 000 Kč, většinou s jedním až dvěma přestupy. Z Vídně jsou letenky o pár tisíc Kč levnější, a to ve stejném období za 18 500 Kč. </p>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/senior/senior_3.png" alt="Příklad možného spoje"></StaticImage>
                        <StyledFigcaption>Příklad ceny zpátečních letenek do Vancouveru s jedním přestupem ve Frankfurtu v termínu od 4. - 16. 9. 2023.</StyledFigcaption>
                    </StyledFigure>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/senior/senior_4.png" alt="Obrázek mapy Vancouveru se zvýrazněnými lokalitami"></StaticImage>
                        <StyledFigcaption>Na mapě vlevo dole Vancouver international Airport a další tipy na místa, která ve Vancouveru stojí za navštívení</StyledFigcaption>
                    </StyledFigure>
                </li>
                <li>
                    <h3>Costa Rica:</h3>
                    <p>Costa Rica je rájem pro milovníky přírody a dobrodružství. Tato středoamerická země je známá svými dechberoucími plážemi, tropickými deštnými lesy a úžasnou biodiverzitou. Zde mohou starší cestovatelé zažít nezapomenutelné zážitky, jako je pozorování zvířat, pěší túry, návštěva termálních pramenů a vodopádů. Costa Rica má také dobře vyvinutý turistický průmysl, který se zaměřuje na pohodlí a bezpečnost návštěvníků.</p>
                </li>
                <li>
                    <h3>Barcelona, Španělsko:</h3>
                    <p>Barcelona je městem, které spojuje jedinečnou architekturu, bohatou kulturu a živé pláže. Pro starší cestovatele je to příjemné místo s mnoha možnostmi pro procházky po krásných ulicích a náměstích. Návštěva ikonických památek, jako je Sagrada Familia a Park Güell, bude nezapomenutelným zážitkem nebo jste fotbaloví fanoušci a raději byste navštívili stadion FC Barcelona nebo rovnou přímo i zápas? Barcelona mimo jiné nabízí vynikající gastronomii, dát si můžete paellu nebo typické churros nebo slavné španělské tapas a skvělá vína. Do Barcelony se letenky pohybují v rozmezí od 3 000 Kč do 5 000 Kč, pokud se kupují s dostatečným předstihem (2-3 měsíce).</p>
                    <StyledFigure>
                        <StaticImage src="../assets/images/blog/senior/senior_5.jpg" alt="Park Güell"></StaticImage>
                        <StyledFigcaption>Park Güell</StyledFigcaption>
                    </StyledFigure>
                </li>
            </BlogList>
            <p>Pokud vás některá z destinací zaujala, pomůžeme vám s plánováním vaší vysněné dovolené, kde si budete moci vychutnat nezapomenutelné zážitky, v krásném prostředí a s komfortem dle vašich požadavků a s přihlédnutím na možnosti vašeho rozpočtu.</p>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
